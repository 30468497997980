

import  alumni from "../../images/workshopimg/Alumni.jpg"
import  confidence_b from "../../images/workshopimg/Confidence-b.jpg"
import   confidence from "../../images/workshopimg/confidence.jpg"
import   corporate from "../../images/workshopimg/Corporate.jpg"
import  placement from "../../images/workshopimg/Placement.jpg"
import  youth from "../../images/workshopimg/Youth.JPG"

const workshopData = [

    {
        img:corporate,
        title: "Corporate Field Visit",
       
    },
    {
        img:confidence_b,
        title: "Confidence-building Workshop",
       
    },
    {
        img:placement,
        title: "Placement Workshop",
       
    },
    {
        img:alumni,
        title: "Alumni Speak",
    },
    {
        img:youth,
        title: "Youth Fest",
    },
    {
        img:confidence,
        title: "Fire Safety Workshop",
    },
  
]

export default workshopData