import React from 'react'


import NizamudinCoursedata from './NizamudinCoursedata'

import Nizamudincourse from './Nizamudincourse'
import NizamudinAlumni from './NizamudinAlumni'
import Nizamudinplacement from './Nizamudinplacement'
import Nizamudincontact from './Nizamudincontact'
import Footer from '../footer/Footer'
import NizamudinHomesliding from './NizamudinHomesliding'
import Navbar from '../navbar/Navbar'

import imgData from './ImageData'
const NizamudinHome = () => {

    const data = {

        head: "Hermann Gmeiner Vocational Training Institute, Nizamuddin",
        text: "We are on a mission to empower disadvantaged youth by skilling them for dignified and sustainable livelihood opportunities of employment.",

        subtext:"Set up in 2011, SOS Vocational Training Centre, Nizamuddin has been devoted to the cause of skilling hundreds of underprivileged youth year after year, preparing them for gainful employment opportunities through quality vocational training.",
        
        subtext2:"Upholding the SOS code of encouraging holistic development of an individual, we ensure that our youth receives training support not only in developing technical expertise but also soft skills that are indispensable to truly ensure that they are market-ready.",
        
        
        subtext3:"Our learner segment ranges from school students in higher secondary grades as well as school drop-outs of employable age who aspire to get professionally trained for seeking employment."

    }
    return (
        <>
            <Navbar/>
             <NizamudinHomesliding/>
            <NizamudinCoursedata data={data} />
            <Nizamudincourse />
            <Nizamudinplacement imgData={imgData}/>
            <NizamudinAlumni link="/nizamalumni" />
            <Nizamudincontact />
            <div style={{background:"#f4f6f6"}}>   <Footer /></div>

        </>
    )
}

export default NizamudinHome
