import React from 'react'
import "./pagecontact.css"

const Pagecontact = () => {
    return (
        <>


            <div className="contactus_main">


                <div className="contactheading">

                    <div className="con_heading">

                       <h2>We'd love to hear from you.</h2> 

                    </div>




                </div>


                <div className="contactinfo">

                    <div>
                        <div className="fc">
                            <div className="ist">Address</div>
                            <div className="znd">VTC Raipur</div>
                            <div className="trd">VTC Raipur SOS Children’s Villages</div>
                            <div className="trd">Vocational Training Centre Airport Road, Mana Camp,</div>
                            <div className="trd">Raipur, Chhattisgarh Pin Code- 492015</div>
                            
                        </div>
                        <div className="fc2">
                            <div className="ist">Contact Person:</div>
                            <div className="znd">Amrish Singh</div>
                        
                        </div>
                        <div className="fc2">
                            <div className="ist">Phone:</div>
                            <div className="znd">0771-7132196</div>
                        
                        </div>
                        <div className="fc2">
                            <div className="ist">Mobile:</div>
                            <div className="znd">0129-5554344</div>
                        
                        </div>
                        <div className="fc2">
                            <div className="ist">Email:</div>
                            <div className="znd">principal.raipurvtc@soscvindia.org</div>
                        
                        </div>
                        <div className="fc">
                            <div className="ist">Socials</div>

                            <div className="detail">

                                <div className="profiles">

                                    <div className="profile">
                                        <a href="/" target="_blank" rel="noopener noreferrer"><i class="fab fa-youtube fa-2x"></i></a>
                                    </div>
                                    <div className="profile">
                                        <a href="/" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook fa-2x"></i></a>
                                    </div>
                                    <div className="profile">
                                        <a href="/" target="_blank" rel="noopener noreferrer"><i class="fab fa-twitter fa-2x "></i></a>
                                    </div>
                                    <div className="profile">
                                        <a href="/" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram fa-2x"></i></a>
                                    </div>
                                </div>
                            </div>
                            
                        
                        </div>
                    </div>


                    <div className="contactform">


                        <div className="form-main">

                        <div className="input-container">

                            <span className=''><i class="far fa-user icon  fa-2x"></i></span>

                            <input type="text" className='forminput' placeholder='Full Name' name="username" />



                        </div>
                        <div className="input-container">

                            <span className=''><i class="far fa-envelope icon fa-2x"></i></span>

                            <input type="email" className='forminput' placeholder='Email' name="email" />



                        </div>
                        <div className="input-container">

                            <span className=''><i class="fa fa-mobile fa-2x icon"></i></span>

                            <input type="text" className='forminput' placeholder='Mobile' name="phone" />



                        </div>
                        <div className="input-container">

                            <span className=''><i class="fa fa-city  fa-2x icon"></i></span>

                            <input type="text" className='forminput' placeholder='City' name="City" />



                        </div>
                        <div className="input-container">

                            <span className=''><i class="fa fa-user-graduate icon fa-2x"></i></span>

                            <input type="text" className='forminput' placeholder='Course' name="grade" />



                        </div>
                        <div className="input-container">

                            <span className=''><i class="far fa-envelope icon fa-2x"></i></span>

                            <input type="text" className='forminput' placeholder='Message' name="message" />



                        </div>

                        <div className="formconbtn">


                            <button className="btn-submit">
                                Submit
                            </button>


                        </div>


                        </div>



                    </div>


                </div>

            </div>









        </>
    )
}

export default Pagecontact
