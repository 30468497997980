import React from 'react'
import { Row, Col } from "react-bootstrap";
import Datacourse from '../CourseOffered/NizamCourse/Datacourse';
import Tallycourse from "../CourseOffered/NizamCourse/Tallycourse"
import Retailcourse from "../CourseOffered/NizamCourse/Retailcourse"
import Officecourse from "../CourseOffered/NizamCourse/Oficecourse"
import { officeCourse,operationData} from '../CourseOffered/Variable';
import "./faridabad.css"
import { dataCourse, talyCourse, reatilCourse } from '../CourseOffered/Variable';
import Operation from '../CourseOffered/Afterschoolcourses/Operation';
const Faridabadcard = () => {
    return (
        <>
            <section className="coursecard">
                <div className="container-fluid">
                    <div style={{ marginBottom: "1.5rem" }}>
                        <h2
                            style={{
                                marginTop: "2rem",
                                textAlign: "center",
                                marginLeft: "-2rem",

                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "42px",
                                lineHeight: "51px",
                            }}
                        >
                            Courses Offered
                        </h2>


                        <div className="course">


                            <Row>
                                <Col>


                                    <Datacourse data={dataCourse} />

                                </Col>
                                <Col>
                                    <Tallycourse data={talyCourse} />

                                </Col>
                                
                                <Col>

                                    <Operation data={operationData}/>

                                </Col>





                            </Row>

                            <Row>
                              
                              <Col sm="4">
                              <Retailcourse data={reatilCourse}/>
                              
                              
                              </Col>

                           

                            </Row>







                        </div>

                    </div>
                </div>

            </section>


        </>
    )
}

export default Faridabadcard
