import React from 'react'

import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import "../../NizamudinTraining/nizamudin.css"

import useCollapse from "react-collapsed";

const Aquaculture = ({data}) => {
    const [isExpanded, setExpanded] = useState(false);
    const { getCollapseProps, getToggleProps } = useCollapse();
    return (
        <>


            <Card style={{ width: '20rem' }}>
                <Card.Img  style={{height:"30vh"}} variant="top" src={data[0].img} />
                <Card.Body style={{ background: "009EE0" }}>
                    <Card.Title style={{ textAlign: "center" }}>{data[0].title}</Card.Title>
                    <Card.Text style={{ textAlign: "center", justifyContent: "center" }}>
                        <p>{data[0].desc}</p>
                        <p {...getCollapseProps()}>

                            <p className='card-text'><span className='heading2'>Eligibility Criteria: </span>{data[0].eligibility}</p>
                            <p className='card-text'><span className='heading2'>Course Duration: </span>{data[0].duration}</p>
                            <p className='card-text'><span className='heading2'>Certification: </span>{data[0].Certification}</p>




                        </p>
                    </Card.Text>
                    <div className="be btn" style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px"
                    }}
                    >
                        <Button
                            {...getToggleProps({
                                onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                            })}




                            className='btn info' style={{
                                position: "relative",
                                borderRadius: "40px",
                                border: "1px solid #009EE0",
                                padding: "10px",
                                outline: "none",
                                color: "#009EE0",
                                fontSize: "15px",
                                fontWeight: "600",
                                textDecoration: "none",
                                backgroundColor: "transparent",
                            }}> {isExpanded ? "Read Less" : "Read More"}</Button>
                    </div>

                </Card.Body>

            </Card>





        </>
    )
}

export default Aquaculture
