import React from 'react'
import { Row, Col } from "react-bootstrap";
import Tailoring from '../CourseOffered/Afterschoolcourses/Tailoring';

import Officecourse from "../CourseOffered/NizamCourse/Oficecourse"
import Retailcourse from '../CourseOffered/NizamCourse/Retailcourse';
import { officeCourse, reatilCourse } from '../CourseOffered/Variable';
import "./faridabad.css"

const Bhopal = () => {
 
    return (
        <>
            <section className="coursecard">
                <div className="container-fluid">
                    <div style={{ marginBottom: "1.5rem" }}>
                        <h2
                            style={{
                                marginTop: "2rem",
                                textAlign: "center",
                                marginLeft: "-2rem",

                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "42px",
                                lineHeight: "51px",
                            }}
                        >
                            Courses Offered
                        </h2>


                        <div className="coursecard">


                            <Row style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>

                                <Col sm="4" style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <Officecourse data={officeCourse} />

                                </Col>

                                <Col sm="4">

                                   <Retailcourse data={reatilCourse}/>

                                </Col>





                            </Row>






                        </div>

                    </div>
                </div>

            </section>


        </>
    )
}

export default Bhopal
