import React from 'react'
import { Row, Col } from "react-bootstrap";
import Operation from '../CourseOffered/Afterschoolcourses/Operation';
import Tailoring from '../CourseOffered/Afterschoolcourses/Tailoring';

import Officecourse from "../CourseOffered/NizamCourse/Oficecourse"
import { officeCourse, tailorData,operationData } from '../CourseOffered/Variable';


const Anangpur = () => {

    return (
        <>







            <section className="coursecard">

        


                    <div style={{ marginBottom: "1.5rem" }}>
                        <h2
                            style={{
                                marginTop: "2rem",
                                textAlign: "center",
                                marginLeft: "-2rem",

                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "42px",
                                lineHeight: "51px",
                            }}
                        >
                            Courses Offered
                        </h2>


                        <div className="coursecard">


                            <Row>
                              
                                <Col sm="4">
                                  
                                 <Operation data={operationData}/>
                               

                                </Col>
                                <Col sm="4">
                                  
                                  <Tailoring data={tailorData} />
                               

                                </Col>
                                
                               




                            </Row>
                            





                        </div>

                    </div>
                


            </section>


        </>
    )
}

export default Anangpur
