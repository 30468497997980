import React from 'react'

const Fcourse = (props) => {
    return (
        <>
            <section className='courseoffer'>


                <div className="container" style={{color: "rgba(0, 0, 0, 0.61)",
    fontWeight: "500"}}>
                    <h2>{props.heading}</h2>

                    <p className='text-center py-2 desc3 mt-5'><b>“Those of us who have been privileged to receive education, skills and
                        experiences and even power must be role models for the next generation of leadership”- Wangari Maathai
                    </b></p>

                    <p style={{textAlign:"justify"}} className='desc2 py-2'>
                        We, at Hermann Gmeiner Vocational Training Institutes, have always aspired to
                        capitalize our privilege for serving those who never received it. Carrying forward the
                        legacy of SOS Children’s Villages year after year, we are always seeking opportunities
                        to create new ripples of transformation.
                    </p>

                    <p  style={{textAlign:"justify"}} className='desc2 py-2'>Staying true to this spirit, we embarked on a journey of taking skill training to every
                        corner possible, which gave birth to Hermann Gmeiner After-school Vocational Training
                        Institutes in 2021. </p>

                    <p className='desc2 py-2' style={{textAlign:"justify"}}>These newly established institutes offer skill training in myriad disciplines, best suited for
                        the people of surrounding local communities, enabling them to tap into their innate
                        potential and generating livelihood opportunities.</p>






                </div>
            </section>

        </>
    )
}

export default Fcourse

