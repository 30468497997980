

import raipur from "../../images/contactpageimg/raipur.jpg"



import bhakatpara from "../../images/contactpageimg/bhakat.jpg"
import Anangpur from "../../images/contactpageimg/Anangpur.png"


import first from "../../images/contactpageimg/5.png"

import second from "../../images/contactpageimg/6.png"

import third from "../../images/contactpageimg/7.png"

import fourth from "../../images/contactpageimg/8.png"
import afterschool from "../../images/contactpageimg/Afterschool.png"
import bhopal from "../../images/contactpageimg/bhopal.jpg"
import faridabad from "../../images/contactpageimg/faridabad.JPG"
import nizam from "../../images/contactpageimg/nizam.jpg"

import palla from "../../images/contactpageimg/palla.png"
const contactData = [

    {
        img:raipur,
        title: "Address",
        subtitle:"VTI Raipur",

        desc:"SOS Children’s Villages Vocational Training CentreAirport Road, Mana Camp, Raipur, ChhattisgarhPin Code- 492015",
        person:" Amrish Singh",
        phone:" 0771-7132196",
        mobile:"+91 9589447399",
        email:"principal.raipurVTI@soscvindia.org"
    },
    {
        img:nizam,
        title: "Address",
        subtitle:"VTI Nizamuddin",
        desc:"SOS Vocational Training Centre A-7, Nizamuddin West, Delhi Pin Code- 110013",
        person:"Bharat Rawat Singh",
        phone:"011-43357299",
        mobile:"+91 7988524913",
        email:"bharat.rawat@soscvindia.org"
    },
    {
        img:bhakatpara,
        title: "Address",
        subtitle:"VTI Bhakatpara",
        desc:"SOS Vocational Training Center, Bhakatpara Dhula, Mangaldoi, Darrang, Assam  Pin Code- 784190",
        person:" Dipankar Hazarika",
        
        mobile:"+91 9706159127",
        email:"Dipankar.Hazarika@soscvindia.org"
        
    },
    {
        img:faridabad,
        title: "Address",
        subtitle:"VTI Faridabad",
        desc:"SOS Vocational Training Centre SOS Enclave, Sector-29 Faridabad, Haryana Pin Code -121008",
        person:" Rakesh Singh, Vikram Chawla",
        phone:" 0771-7132196",
        mobile:"+91-8765814857/+91-9899786561",
        email:"hgpsanangpur@soscvindia.org"
    },
    {
        img:palla,
        title: "Address",
        subtitle:"VTI Palla",
        desc:`SOS Vocational Training Centre
        Hermann Gmeiner School, Village- Palla, District-Nuh, Haryana
        Pin Code- 122107
        
        `,
        person:"Adarsh Tiwari",
        phone:"+91-6386736007",
        mobile:"+91-9899786561",
        email:`admin.hgpsanangpur@soscvindia.org`
    },
    {
        img:Anangpur,
        title: "Address",
        subtitle:"VTI Anangpur",
        desc:`SOS Vocational Training Centre
        Anangpur, Faridabad, Haryana
        Pin Code- 121004
        `,
        person:"Vikram Chawla",
       
        mobile:"91-9899786561",
        email:"admin.hgpsanangpur@soscvindia.org"
    },
    {
        img:afterschool,
        title: "Address",
        subtitle:"VTI Varanasi",
        desc:`Hermann Gmeiner School, Daniyalpur
        Chaubeypur, Varanasi, Uttar Pradesh,
        Pin Code- 221104
        
        `,
        person:"D.N. Rajput",
       
        mobile:"+91-9453 899 173",
        email:"dnrajput03130@gmail.com"
    },
    {
        img:bhopal,
        title: "Address",
        subtitle:"VTI Bhopal",
        desc:`Hermann Gmeiner School,
        Khajuri Kalan Road, Piplani, Bhopal
        Pin Code- 462022
        `,
        person:"Nitin Sahu",
        phone:"0755-2756236",
        mobile:"8518881820",
        email:"admin.bhopalhgps@soscvindia.org"
    },
   
]

export default contactData