import React from 'react'
import Footer from '../footer/Footer'


import Pagecontact from './Pagecontact'

const Pagecontacthome = () => {
  return (
    <>
      
      <Pagecontact/>
      <Footer/>
    </>
  )
}

export default Pagecontacthome
