import React from 'react'

import Nizamudincard from './Nizamudincard'

import cardData from './NizamuddinCardData'
const Nizamudincourse = () => {

  

  return (
    <>

    


      <Nizamudincard  data={cardData}/>
   
   
    </>
  )
}

export default Nizamudincourse
