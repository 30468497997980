import React from 'react'
import Contact from './Contact'
import "./nizamudin.css"

const Nizamudincontact = () => {
    return (
        <>

            <section class="ncontact my-4">
                <div class="container-fluid ">
                    <h2 class="ccc text-center">Contact Us</h2>
                    <div class="firstsection">

                        <Contact/>
                       
                        <div class="avtar">
                            <div class="secondsection " id="conimg"><img src="./images/contact.png" alt="" /></div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default Nizamudincontact
