

import dataentry from "../../images/Nizamimages/dataentry.jpg"
import tally from "../../images/Nizamimages/tally.jpg"
import web from "../../images/Nizamimages/web.webp"
import office from "../../images/Nizamimages/office.webp"
import python from "../../images/Nizamimages/python.jpg"
import reatil from "../../images/Nizamimages/retail.jpg"
import first from "../../images/alumImg/first.jpg"
import second from "../../images/alumImg/second.jpg"
import third from "../../images/alumImg/third.jpg"
const cardData = [

    {
        id:1,
        img:dataentry,
        title: "Data Entry Operator",
        desc:"A basic level course that offers proficiency in MS Office suite and helps develop a gamut of skills essential for a data entry and office executive positions.",
        eligibility:"Min 10th Pass & 18 years of age",
        duration:"3 Months",
        Certification:"Niit Foundation"
    },
    {
        id:2,
        img:tally,
        title: "Tally Prime",
        desc:"A comprehensive accounting course that fully prepares one to manage  accounting of any firm, with in-depth knowledge and expertise of the Tally Prime Software.",
        eligibility:"Min 12th Pass & 18 years of age",
        duration:"6 Months",
        Certification:"Tally India Pvt.Ltd."
    },
    {
        id:3,
        img:web,
        title: "Web Designing & Desktop Publishing",
        desc:"One of the most sought after technical courses that offers a massive head-start for a lucrative career in IT-ITES industry as a web and graphic designer.",
        eligibility:"Min 12th Pass & 18 years of age",
        duration:"6 Months",
        Certification:"Niit Foundation"
    },
    {
        id:4,
        img:office,
        title: "Office Operations Executive",
        desc:"The perfect office executive course that prepares a candidate to build dexterity in managing routine office activities and inculcating admirable interpersonal skills for a successful career.",
        eligibility:"Min 12th Pass & 18 years of age",
        duration:"3 Months",
        Certification:"Management & Entrepreneurship Sector Skill Council"
    },
    {
        id:5,
        img:reatil,
        title: "Retail & Sales cum Cashier",
        desc:"The course prepares youth for performing the role of a Retail Associate wherein they are thoroughly trained in Merchandise Management, developing customer relationship skills and delivering quality service.",
        eligibility:"Min 10th Pass & 18 years of age",
        duration:"2 Months",
        Certification:"Retailers Association’s Skill Council of India."
    },
    {
        id:6,
        img:python,
        title: "Python Fundamentals",
        desc:"A foundational course for learning Core Python, Ananconda IDE-Spyder, Jupyter, Google Colab, NumPy, Pandas, Matplotlib, sklearn, etc., leading to careers in Data Analytics, Machine Learning and much more.",
        eligibility:"Min 10th Pass & 18 years of age",
        duration:"6 Months",
        Certification:"NASSCOM"
    },
]

export default cardData


export const alumniData = [



    {
        img:first,
        Name:"Rahul",
        text:"Rahul is a spirited young boy of 21. He enrolled himself in the  Web Designing Course at Hermann Gmeiner Vocational Training Institute in April 2019. At that time, he had recently graduated from school and was seeking to learn a skill that would earn him a diploma of value in the job industry. He came to learn about the VTI through a friend and was excited to enroll himself for the Web Designing Course. Since that day, Rahul has come a long way, and he says “VTI faculty introduced me to something that I can’t explain in words. They  introduced me to my passion, and not only that, I can now earn money from anywhere through freelance projects.” Rahul started working on freelance projects as a Full Stack React Developer even before he completed his course. His initial earnings were Rs.20,000-25,000 per month, which has now increased upto Rs. 3000-5000 per day. Rahul says “Because of learning Web Designing, I’m able to earn good money by devoting only afew hours to work in a day, which also gives me the free time to develop my skills and deepen my knowledge further.” Rahul Shared that his aim is to become an entrepreneur in this field, connect with clients from all over the world and earn a six-figure income for his family and himself. He further added “my plans may fail, but I will always aim for the stars so that I feel inspired  to continue growing further.”"
    },

    {
        img:second,
        Name:"Mohd.Faizan",
        text:"Faizan, coming from a humble family, is a simple boy who cherished the dream of being able to afford a life of comfort for his family. On his quest for fulfilling this dream and elevating their living conditions, Faizan discovered about the Account Assistant using Tally Course at Hermann Gmeiner Vocational Training Institute, Nizamuddin in 2018, and decided to join the course to gain some professional skills. His dedication and sincerity towards learning always reflected in his attitude and participation in classes. After successfully completing his course from VTI, Nizamuddin, he was sent for a month internship at Marks &amp; Spencer and was offered a job by the company afterwar as a result of his commendable performance. Faizan shared that the internship prog helped him learn a lot; especially the massive importance of proper communication  interpersonal skills. He also mentioned that he loved the environment at his workpl and felt deeply grateful for this learning expert. Within one month of working as a full time employee at Marks &amp; Spencers, Faizan was promoted as a buddy coach and offered a monthly salary of INR 27,600. Faizan couldn’t contain his joy at this achievement and shared that he is really excited to work hard and unleash his highest potential."
    },

    {
        img:third,
        Name:"Durgesh",
        text:"Durgesh is a 19-year-old young boy with a dynamic personality. He enrolled himself in our Web Designing course at Hermann Gmiener Vocational Training Institute, Nizamuddin in April 2022. At the time, he was a recent undergraduate and was looking for a skill that would help him land a secure job. He came to find out about the institute through a pass-out student and was excited to learn Web Designing. In an endeavor to support his family, Durgesh took up a night shift job as an MIS assistant, while he attended his classes in the morning. His initial earnings were Rs. 10,000 - 12,000 per month, but after completing his course from VTI, the company offered him to manage the company website. He was really excited on receiving this opportunity and beamed with joy on sharing that his earnings have increased to Rs/- 15,000- 20,000/- per month. Durgesh is also able to take up freelance projects whenever his schedule allows. Durgesh shared “My parents are extremely proud of my job as I’m the first one in the family who is working in this field and doing a computer job. The increase in earnings has brought a lot of mental peace and we feel hopeful for a better future. My father was the sole earning member earlier and would feel burdened to take care of things all alone. I feel grateful that I’m now able to contribute to the family income.”"
    },






]