import React, { useState } from 'react'
import "./sidebar.css"
import { Link } from "react-router-dom"

import { BsChevronRight } from "react-icons/bs";
const Sidebar = () => {

    const [isSideNav, setIsSideNav] = useState(false);

    const [open, setOpen] = useState(false)
    const showData = (state) => {

        setOpen(!state)

    }
    const [open1, setOpen1] = useState(false)
    const showData1 = (state) => {



        setOpen1(!state)

    }
    return (
        <>

       
            <div className="sidebar_main">

                <ul>

                    <li><Link to="/">Home</Link></li>


                    <li>
                        <div className='abt-btn' onClick={(e) => { showData(open) }}>About Us

                            <span className='fas fa-caret-down first'> </span>

                        </div>

                        {open && (

                            <ul className='abt-show'>
                                <li><Link to="/about">SOS Children's Village</Link></li>
                                <li><Link to="/">Know More About SOS Children Village of India</Link></li>
                            </ul>


                        )}


                    </li>
                    <li>
                        <div onClick={(e) => { showData1(open1) }} className='vt-btn'>VTIs

                            <span className='fas fa-caret-down second'> </span>

                        </div>

                        {open1 && (


                            <ul className='vt-show'>
                                <li><Link to="/nizamudin">Hermann Gmeiner VTI Nizamuddin</Link></li>
                                <li><Link to="/raipur"> Hermann Gmeiner VTI  Raipur</Link></li>
                                <li><Link to="/bhakatpara"> Hermann Gmeiner VTI Bhakatpara</Link></li>
                                <li><Link to="/school">Hermann Gmeiner After Schools VTIs</Link></li>

                            </ul>





                        )}



                    </li>


                    <li><Link to="/">Donors</Link></li>
                    <li><Link to="/">Faculty</Link></li>
                    <li><Link to="/contactus">Contact Us</Link></li>










                </ul>




            </div>

        </>
    )
}

export default Sidebar
