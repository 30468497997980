import React from 'react'
import { Link } from 'react-router-dom'
import "./infra.css"
const Infracard = (props) => {
    return (
        <>

            {props.data.map((e,ind) => {



                return <div className="col-lg-4" key={ind}>

                    <div class="card">
                        <img class="card-img-top" src={e.img} alt="Card image cap" />
                        <div class="card-body">
                            <h5 class="card-title">{e.title}</h5>
                            <p class="card-text">{e.desc}</p>
                            <Link style={{color:"#009EE0"}} to={e.link}  id="infra-btn">Know More</Link>
                           
                        </div>
                    </div>


                </div>



            })}



        </>
    )
}

export default Infracard
