import React from 'react'
import { Row, Col } from "react-bootstrap";
import Aquaculture from '../CourseOffered/BhakatparaCourse/Aquaculture';
import Beekeeper from '../CourseOffered/BhakatparaCourse/Beekeeper';

import Handloom from '../CourseOffered/BhakatparaCourse/Handloom';
import Horiculture from '../CourseOffered/BhakatparaCourse/Horiculture';

import { handloomData, aquaData, beeData, horicultureData } from '../CourseOffered/Variable';
const Bhakatpatacard = () => {
    return (
        <section className="coursecard">

            <div className="container-fluid">




                <div style={{ marginBottom: "1.5rem" }}>
                    <h2
                        style={{
                            marginTop: "2rem",
                            textAlign: "center",
                            marginLeft: "-2rem",

                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "42px",
                            lineHeight: "51px",
                        }}
                    >
                        Courses Offered
                    </h2>


                    <div className="coursecard">


                        <Row>
                            <Col lg="4">

                                <Handloom data={handloomData} />


                            </Col>
                            <Col lg="4">

                                <Aquaculture data={aquaData} />



                            </Col>
                            <Col lg="4">

                                <Beekeeper data={beeData} />


                            </Col>








                        </Row>

                        <Row>
                            <Col lg="4">


                                <Horiculture data={horicultureData} />
                            </Col>
                        </Row>








                    </div>

                </div>
            </div>

        </section>
    )
}

export default Bhakatpatacard
