import React from 'react'
import Branches from './branches/Branches'
import Footer from './footer/Footer'
import Home from './Home/Home'
import Infra from './infra/Infra'
import Navbar from './navbar/Navbar'
import Workshop from './workshop/Workshop'

const LandingPage = () => {
  return (
    <div>
      <Navbar/>
      <Home/>
      <Branches/>
      <Infra/>
      <Workshop/>
      <Footer/>
    </div>
  )
}

export default LandingPage
