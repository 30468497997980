
import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";


import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./faridabad.css"
import first from "../../images/afterschoolslideimg/1.jpg"
import second from "../../images/afterschoolslideimg/2.jpg"
import third from "../../images/afterschoolslideimg/3.jpg"
import fourth from "../../images/afterschoolslideimg/4.jpg"
import fifth from "../../images/afterschoolslideimg/5.jpg"
import sixth from "../../images/afterschoolslideimg/6.jpg"
import seventh from "../../images/afterschoolslideimg/7.jpg"


import { Pagination, Navigation, Autoplay } from "swiper";

const Faridabadslidhome = () => {
  return (
    <>
     <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <img src={first} alt="" />
                    <center>
                        <div className="bottomleft box">
                            <h5 className="h">Hermann Gmeiner After School Vocational Training Institutes</h5>
                        </div>
                    </center>

                </SwiperSlide>
                <SwiperSlide>
                    <img src={second} alt="" />
                    <center>
                        <div className="bottomleft box">
                            <h5 className="h">Hermann Gmeiner After School Vocational Training Institutes</h5>
                        </div>
                    </center>

                </SwiperSlide>
                <SwiperSlide>
                    <img src={third} alt="" />
                    <center>
                        <div className="bottomleft box">
                            <h5 className="h">Hermann Gmeiner After School Vocational Training Institutes</h5>
                        </div>
                    </center>

                </SwiperSlide>
                <SwiperSlide>
                    <img src={fourth} alt="" />
                    <center>
                        <div className="bottomleft box">
                            <h5 className="h">Hermann Gmeiner After School Vocational Training Institutes</h5>
                        </div>
                    </center>

                </SwiperSlide>
                <SwiperSlide>
                    <img src={fifth} alt="" />
                    <center>
                        <div className="bottomleft box">
                            <h5 className="h">Hermann Gmeiner After School Vocational Training Institutes</h5>
                        </div>
                    </center>

                </SwiperSlide>
                <SwiperSlide>
                    <img src={sixth} alt="" />
                    <center>
                        <div className="bottomleft box">
                            <h5 className="h">Hermann Gmeiner After School Vocational Training Institutes</h5>
                        </div>
                    </center>

                </SwiperSlide>
                <SwiperSlide>
                    <img src={seventh} alt="" />
                    <center>
                        <div className="bottomleft box">
                            <h5 className="h">Hermann Gmeiner After School Vocational Training Institutes</h5>
                        </div>
                    </center>

                </SwiperSlide>
            



            </Swiper>
      
    </>
  )
}

export default Faridabadslidhome
