import React from 'react'
import Footer from '../footer/Footer'
import Home from '../Home/Home'
import Navbar from '../navbar/Navbar'
import Sidebar from '../Sidebar/Sidebar'
import Alumni from './Alumni'

const Inspired = ({alumniData}) => {
  return (
    <>

    <Navbar />
    <Home/>

    <div className='contentmain-cont'>

        <div className='width'>
            <Sidebar />
        </div>


        <Alumni data={alumniData}/>



       


    </div>


    <div style={{ background:"#F4F6F6"}}>
    <Footer/>
    </div>

</>
  )
}

export default Inspired
