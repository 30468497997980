import React from 'react'


import "./nizamudin.css"
import { Row, Col } from "react-bootstrap";
import NizaCard from './NizaCard'
import Datacourse from '../CourseOffered/NizamCourse/Datacourse';

import Webcourse from '../CourseOffered/NizamCourse/Webcourse';
import Officecourse from "../CourseOffered/NizamCourse/Oficecourse"
import Tallycourse from "../CourseOffered/NizamCourse/Tallycourse"
import { dataCourse,webCourse,talyCourse,officeCourse,reatilCourse,pythonCourse} from '../CourseOffered/Variable';

import { tallyData } from '../CourseOffered/Variable';
import Retailcourse from '../CourseOffered/NizamCourse/Retailcourse';
import Pythoncourse from '../CourseOffered/NizamCourse/Pythoncourse';




const Nizamudincard = () => {



    return (
        <>







            <section className="coursecard">

        


                    <div style={{ marginBottom: "1.5rem" }}>
                        <h2 className='myheading'
                            
                        >
                            Courses Offered
                        </h2>


                        <div className="coursecard">


                            <Row>
                              
                                <Col lg="4">
                                  
                                  <Datacourse data={dataCourse}/>
                               

                                </Col>

                                <Col lg="4">
                                  
                                  <Tallycourse data={talyCourse} />
                               

                                </Col>
                                <Col lg="4">

                                    <Webcourse data={webCourse}/>


                                </Col>
                               




                        
                            
                                <Col lg="4">

                                    <Officecourse data={officeCourse}/>


                                </Col>
                                <Col lg="4">

                                    <Retailcourse data={reatilCourse}/>


                                </Col>
                                <Col lg="4">

                                    <Pythoncourse data={pythonCourse}/>


                                </Col>
                               




                            </Row>





                        </div>

                    </div>
                


            </section>


        </>
    )
}

export default Nizamudincard
