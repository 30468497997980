import React from "react";

import Varanasi from "../Faridabad Training/Varanasi";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

import Nizamudin from "../NizamudinTraining/Nizamudin";
import Nizamudincontact from "../NizamudinTraining/Nizamudincontact";

import Nizamudinplacement from "../NizamudinTraining/Nizamudinplacement";
import Bcourse from "./Bcourse";
import Bhakatparacontact from "./Bhakatparacontact";
import Bhakatparahomesliding from "./Bhakatparahomesliding";
import Bhakatpatacard from "./Bhakatpatacard";
import Contact from "./Contact.js";
const Bhakatpara = () => {
  return (
    <>
      <Navbar />
      <Bhakatparahomesliding />
      <Bcourse />

      <Bhakatpatacard />

      <Bhakatparacontact />
      <div style={{ background: "#f4f6f6" }}>
        {" "}
        <Footer />
      </div>
    </>
  );
};

export default Bhakatpara;
