import React from 'react'

const Rcourse = () => {
    return (
        <>

            <section className='courseoffer'>


                <div className="container">
                    <h2>Hermann Gmeiner Vocational Training Institute, Raipur</h2>
                    
                    <p className='desc2 pt-4'>
                        SOS Vocational Training centre, Raipur came into existence in 2010, in an endeavour to provide placement-linked skill development and livelihood creation opportunities to the disadvantaged youth of society.
                    </p>
                    <p className='text-center py-2 desc3'><b>Holding fast to our belief that even one empowered individual can spark great transformation within their family and communities, we have striven to support vulnerable families one individual at a time.
                    </b></p>
                    <p className='desc2 py-2'>Over its decade-long contribution in vocational training, SOS VTC Raipur has skilled thousands of youth, enabled them to secure meaningful employment with reputed organisations through its dedicated efforts in providing them placement support in the form of recruitment drives, job fairs and liasoning with reliable placement partners. </p>






                </div>
            </section>


        </>
    )
}

export default Rcourse
