import React from 'react'
import Footer from '../footer/Footer'

import Navbar from '../navbar/Navbar'
import NizamudinAlumni from '../NizamudinTraining/NizamudinAlumni'
import Nizamudincard from '../NizamudinTraining/Nizamudincard'

import Nizamudinplacement from '../NizamudinTraining/Nizamudinplacement'
import raipurcardData from './RaipurcardData'
import RaipurHomesliding from './RaipurHomesliding'
import Rcourse from './Rcourse'

import imgData from './ImgData'
import Raipurcard from './Raipurcard'
import Contact from './RaipurContact'
import RaipurContact from './RaipurContact'
import Contactsec from './Contactsec'
const RaipurHome = () => {

    
  return (
    <>
      
      <Navbar/>
      <RaipurHomesliding/>
      <Rcourse/>
      <Raipurcard/>
      <Nizamudinplacement imgData={imgData}/>
      <NizamudinAlumni link="/raipuralumni"/>
       <Contactsec/>
      <div style={{background:"#f4f6f6"}}>   <Footer /></div>
    </>
  )
}

export default RaipurHome
