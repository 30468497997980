import React, { useState } from 'react'
import { NavLink } from "react-router-dom";
import "./navbar.css"

const Navbar = () => {


    const [show, setShow] = useState("mobile-hide");


    const handlesubmit = () => {

        console.log("Hello")


        if(show=="mobile-hide")
        {
            setShow("mobile-show")
        }else{


            setShow("mobile-hide")
        }





    }
    return (
        <>


            <section  className="header">



                <NavLink  to="/"><img className='logo1' src="./images/logo.svg" alt="" /></NavLink>




                <div>

                    <ul className='navbar-list' id={show}>
                        <li><NavLink exact activeClassName="active"   to="/">Home</NavLink></li>
                        <li><NavLink exact activeClassName="active"  to="/about">About Us</NavLink>


                            <div className="sub-menu">
                                <ul>
                                    <li><NavLink to="/about">SOS Children's Villages</NavLink><i className='fas fa-angle-right'></i></li>

                                    <li><a href="https://www.soschildrensvillages.in/" target="_blank">Know More About Sos Children's Villages</a><i className='fas fa-angle-right'></i></li>
                                </ul>
                            </div>


                        </li>
                        <li><NavLink exact activeClassName="active"  to="/nizamudin">VTIs</NavLink>

                            <div className="sub-menu1">
                                <ul>
                                    <li><NavLink to="/nizamudin">Hermann Gmeiner Vocational Training Institute, Nizamuddin</NavLink><i className='fas fa-angle-right'></i></li>
                                    <li><NavLink to="/raipur">Hermann Gmeiner Vocational Training Institute, Raipur</NavLink><i className='fas fa-angle-right'></i></li>
                                    <li><NavLink to="/bhakatpara">Hermann Gmeiner Vocational Training Institute, Bhakatpara</NavLink><i className='fas fa-angle-right'></i></li>
                                    <li><NavLink to="/school">Hermann Gmeiner After School Vocational Training Institutes</NavLink><i className='fas fa-angle-right'></i></li>

                                </ul>
                            </div>



                        </li>
                        <li><NavLink exact activeClassName="active"  to="/donors">Donors</NavLink></li>
                        <li><NavLink exact activeClassName="active"  to="/faculty">Faculty</NavLink></li>
                        <li><NavLink exact activeClassName="active"   to="/contactus">Contact Us</NavLink></li>

                        <div id='close'><i onClick={handlesubmit} class="fas fa-times"></i></div>
                    </ul>


                </div>


                <div className="mobile">
                    <i id='bar' onClick={handlesubmit}  className='fas fa-outdent'></i>
                </div>


            

            </section>


        </>
    )
}

export default Navbar
