import React from 'react'
import Home from '../../Home/Home'
import Navbar from '../../navbar/Navbar'
import Sidebar from '../../Sidebar/Sidebar'
import Footer from "../../footer/Footer"


import "../../About/about.css"
import PagesCard from '../PagesCard'


const Classroom = ({data,imgData}) => {


  return (
    <>
      <Navbar />
      <Home />

      <div className='contentmaincont'>

       
          <Sidebar />
        


        <div className="aboutus">

          <div className="firsttext">
            <div className='mandatory_heading1'>{data[0].title}</div>
            <p style={{
              fontWeight: "700",
              fontSize: "20px",
              textAlign: "justify"
            }}>{data[0].main_desc}</p>

            <p style={{color:"rgba(0, 0, 0, 0.61)"}}>{data[0].desc}</p>
            <p style={{color:"rgba(0, 0, 0, 0.61)"}}>{data[0].desc2}</p>
            <p style={{color:"rgba(0, 0, 0, 0.61)"}}>{data[0].desc3}</p>

          </div>
          
        <PagesCard data={imgData}/>

        </div>



      </div>

      <div className="mt-5" style={{background:"#F8F9F9"}}>
      <Footer/>
      </div>

    </>
  )
}

export default Classroom
