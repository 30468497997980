import React from 'react'

const Courseprovide = ({heading}) => {
  return (
    <section className='courseoffer'>


                <div className="container">
                    <h2>{heading}</h2>

                





                </div>
            </section>
  )
}

export default Courseprovide
