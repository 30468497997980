import React from 'react'
import Navbar from '../navbar/Navbar'
import Contactcard from './Contactcard'
import "./contactus.css"
import contactData from './Contactdata'
import Footer from "../footer/Footer"
// import Contactnavbar from './Contactnavbar'
const Contactus = () => {
    return (
        <>

            <Navbar />
            <section className="contact-us">
                <div className="container my-5">

                    <div className="contactheading">

                        <div className="con_heading">
                            <h2> We'd love to hear from you.</h2></div>
                    </div>

                    
                        <div className="row my-2">

                            <Contactcard data={contactData} />



                        </div>
                



                </div>

            </section>


            <Footer />




        </>
    )
}

export default Contactus
