import React from 'react'
import Navbar from "../../navbar/Navbar"
import Footer from '../../footer/Footer'
import Home from '../../Home/Home'
import Sidebar from '../../Sidebar/Sidebar'
import { safetyData } from '../infracarddata'
import { safetyImg } from '../infracarddata'
import { safetymap } from '../infracarddata'
import PagesCard from '../PagesCard'
const Safety = () => {
    return (
        <>

            <Navbar/>
            <Home />

            <div className='contentmaincont'>

             
                    <Sidebar />
              


                <div className="aboutus">

                    <div className="firstext">
                        <div className='mandatory_heading1'>{safetyData[0].title}</div>


                        <p style={{
                            fontWeight: "600",
                            fontSize: "20px",
                            marginTop:"5px",
                            textAlign: "justify"
                        }}>{safetyData[0].desc}</p>


                    </div>
                    {safetymap.map((e,ind)=>{
                         
                         return<div className="firsttext">


                            <div style={{
                                fontSize: "20px",
                                fontWeight: "700",
                                color: "#009EE0",
                            }}>{e.title}</div>

                            <p>{e.desc}</p>




                         </div>








                    })}


                    <PagesCard data={safetyImg}/>

                </div>


            </div>

            <div className="mt-5" style={{ background: "#F8F9F9" }}>
                <Footer />
            </div>

        </>
    )
}

export default Safety
