import React from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

import Nizamudin from "../NizamudinTraining/Nizamudin";
import Nizamudincontact from "../NizamudinTraining/Nizamudincontact";

import Nizamudinplacement from "../NizamudinTraining/Nizamudinplacement";
import Anangpur from "./Anangpur";
import Bhopal from "./Bhopal";
import Courseprovide from "./Courseprovide";

import Faridabadcard from "./Faridabadcard";

import Faridabadslidhome from "./Faridabadslidhome";
import Fcourse from "./Fcourse";
import PallaCard from "./PallaCard";
import Varanasi from "./Varanasi";
import "./faridabad.css";
import FaridabadContactSec from "./FaridabadContactSec";

const Faridabadhome = () => {
  return (
    <>
      <Navbar />

      <Faridabadslidhome />

      <Fcourse heading="Hermann Gmeiner After School Vocational Training Institutes" />

      <Courseprovide heading="Hermann Gmeiner Vocational Training Institute, Faridabad" />
      <Faridabadcard />

      <Courseprovide heading="Hermann Gmeiner Vocational Training Institute, Palla" />

      <PallaCard />

      <Courseprovide heading="Hermann Gmeiner Vocational Training Institute, Anangpur" />
      <Anangpur />

      <Courseprovide heading="Hermann Gmeiner Vocational Training Institute, Bhopal" />

      <Bhopal />

      <Courseprovide heading="Hermann Gmeiner Vocational Training Institute, Varanasi" />
      <Varanasi />
      <FaridabadContactSec />
      {/* <Nizamudincontact /> */}

      <div style={{ background: "#f4f6f6" }}>
        {" "}
        <Footer />
      </div>
    </>
  );
};

export default Faridabadhome;
