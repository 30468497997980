import React from 'react'


import { Row, Col } from "react-bootstrap";
import Datacourse from '../CourseOffered/NizamCourse/Datacourse';
import Assistant from '../CourseOffered/Raipurcourse/Assistant';
import Associatedekstop from '../CourseOffered/Raipurcourse/Associatedekstop';
import Automotive from '../CourseOffered/Raipurcourse/Automotive';
import Vechile from '../CourseOffered/Raipurcourse/Vechile';
import { dataCourse,associatDekstop,vechileData,automativeData,assistantData } from '../CourseOffered/Variable';
 
const Raipurcard = () => {
    return (
        <>







            <section className="coursecard">

                <div className="container-fluid">

               


                    <div style={{ marginBottom: "1.5rem" }}>
                        <h2
                            style={{
                                marginTop: "2rem",
                                textAlign: "center",
                                marginLeft: "-2rem",

                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "42px",
                                lineHeight: "51px",
                            }}
                        >
                            Courses Offered
                        </h2>


                        <div className="coursecard">


                            <Row>
                                <Col lg="4" >

                                    <Datacourse data={dataCourse} />


                                </Col>
                                <Col lg="4" >

                                   <Associatedekstop data={associatDekstop}/>


                                </Col>
                                <Col lg="4" >

                                  <Vechile data={vechileData}/>


                                </Col>





                            </Row>
                            <Row > 
                                <Col lg="4">
                                  
                                  <Automotive data={automativeData}/>
                           


                                </Col>
                                <Col lg="4">

                                   <Assistant data={assistantData}/>


                                </Col>
                               




                            </Row>





                        </div>

                    </div>
                </div>

            </section>


        </>
    )
}

export default Raipurcard
