import React from 'react'

const Bcourse = () => {
    return (
        <div>

            <section className='courseoffer'>


                <div className="container">
                    <h2>Hermann Gmeiner Vocational Training Institute, Bhakatpara</h2>

                    <p className='desc2 py-1 pt-4'>SOS Children’s Villages has always blazed trails when it comes to uplifting and empowering the most marginalized communities of society.
                    </p>
                    <p className='desc2 py-1'>The Vocational Training Centre at Bhakatpara that offers skill training in Jacquard handloom weaving, is one such initiative that aims at creating an ecosystem of support and advancement for the rural women of the region.
                    </p>
                    <p className='desc2 py-1 mb-1'>Handloom weaving has a significant impact on the socio-economic life of the State especially in the Darrang district. Providing part time and full-time employment opportrunities to these weavers, the sector plays a crucial role in terms of employment generation and socio-economic development of the district.
                    </p>
                    <p className='desc2 py-1 mb-1'>As per Assam Skill Gap study report 2021, manufacture of textile falls in “High Investment Potential Sectors” and “High Aspiration Sectors” category for Daraang district of Assam.
                    </p>
                    <p className='desc2 py-1 mb-1'>Furthermore, according to Census 2011, the workforce participation rate of Darrang is 34.99 percent and the female workforce participation rate is 8.40 percent.
                    </p>
                    <p className='desc2 py-1 mb-1'>Identifying these factors, the VTC at Bhakatpara has been established to provide structured skill up-gradation training in Jacquard Handloom Weaving. It ensures that the community has access to technologically sophisticated looms and enhancement of skills for greater productivity and efficiency.
                    </p>






                </div>
            </section>



        </div>
    )
}

export default Bcourse
