import React from "react";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "swiper/css/bundle";
import "bootstrap/dist/css/bootstrap.min.css";

import RaipurHome from "./components/Raipur Training/RaipurHome";
import Faridabadhome from "./components/Faridabad Training/Faridabadhome";
import Bhakatpara from "./components/BhakatparaTraining/Bhakatpara";
import LandingPage from "./components/LandingPage";
import NizamudinHome from "./components/NizamudinTraining/NizamudinHome";
import Abouthome from "./components/About/Abouthome";
import Pagecontacthome from "./components/PageContact/Pagecontacthome";
import Contactus from "./components/Maincontact/Contactus";
import ScrollToTop from "./components/Scrooltotop";
import Donor from "./components/Donor/Donor";
import Donorhome from "./components/Donor/Donorhome";
import Facultyhome from "./components/Faculty/Facultyhome";
import Classroom from "./components/infra/Pages/Classroom";
import { alumniData } from "./components/NizamudinTraining/NizamuddinCardData";
import { classroomData } from "./components/infra/infracarddata";
import { computerData } from "./components/infra/infracarddata";
import Safety from "./components/infra/Pages/Safety";
import { internetData } from "./components/infra/infracarddata";
import Otherfacilities from "./components/infra/Pages/Otherfacilities";
import Inspired from "./components/Inspired/Inspired";
import { raipurAlumniData } from "./components/Raipur Training/RaipurcardData";

import {
  classroomImg,
  labImg,
  internetImg,
} from "./components/infra/infracarddata";
import ComputerLabs from "./components/infra/Pages/ComputerLabs";
import Privacy from "./components/privacy/Privacy";
function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/nizamudin" element={<NizamudinHome />} />
          <Route path="/raipur" element={<RaipurHome />} />
          <Route path="/school" element={<Faridabadhome />} />
          <Route path="/bhakatpara" element={<Bhakatpara />} />
          <Route path="/about" element={<Abouthome />} />
          <Route path="/pagecontact" element={<Pagecontacthome />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/donors" element={<Donorhome />} />
          <Route path="/faculty" element={<Facultyhome />} />
          <Route
            path="/classrooms"
            element={<Classroom data={classroomData} imgData={classroomImg} />}
          />
          <Route path="/computerlabs" element={<ComputerLabs />} />
          <Route
            path="/internet"
            element={<Classroom data={internetData} imgData={internetImg} />}
          />
          <Route path="/safety" element={<Safety />} />
          <Route path="/otherfacilites" element={<Otherfacilities />} />
          <Route
            path="/nizamalumni"
            element={<Inspired alumniData={alumniData} />}
          />
          <Route
            path="/raipuralumni"
            element={<Inspired alumniData={raipurAlumniData} />}
          />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
