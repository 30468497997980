

import dataentry from "../../images/raipurimg/dataentry.jpg"
import dekstop  from "../../images/raipurimg/dekstop.jpg"
import vechile  from "../../images/raipurimg/vechile1.jpg"
import automative  from "../../images/raipurimg/automative.jpg"
import electrician  from "../../images/raipurimg/electrician.jpg"
import first from "../../images/alumImg/fourth.jpg"
import second from "../../images/alumImg/fifth.jpg"
import third from "../../images/alumImg/six.jpg"

const raipurcardData = [

    {
        img:dataentry,
        title: "Data Entry Operator",
        desc:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit delectus odit, saepe magni at rem ad, assumenda debitis a asperiores labore velit mollitia. Deleniti, amet"
    },
    {
        img:dekstop,
        title: "Associate- Desktop Publishing ",
        desc:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit delectus odit, saepe magni at rem ad, assumenda debitis a asperiores labore velit mollitia. Deleniti, amet"
    },
    {
        img:vechile,
        title: "Light Motor Vehicle Driver Level 3",
        desc:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit delectus odit, saepe magni at rem ad, assumenda debitis a asperiores labore velit mollitia. Deleniti, amet"
    },
    {
        img:automative,
        title: "Automotive Service Technician",
        desc:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit delectus odit, saepe magni at rem ad, assumenda debitis a asperiores labore velit mollitia. Deleniti, amet"
    },
    {
        img:electrician,
        title: "Assistant Electrician",
        desc:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit delectus odit, saepe magni at rem ad, assumenda debitis a asperiores labore velit mollitia. Deleniti, amet"
    },
 
]

export default raipurcardData


export const raipurAlumniData = [

 
    {   img:first,
        Name:"Ranjeeta Das",
        text:"Ranjeeta Das is a spirited and hard-working girl from Mana Camp, Raipur. She has been single-handedly raised by her father who works as a daily wage worker. Having faced many harsh realities early on, she discontinued her studies and started working in a beauty parlor in an attempt to share her family’s burdens; however her earnings were limited to INR 3000/- per month. Things changed for Ranjeeta when she came to know about Hermann Gmeiner Vocational Training Institute, Raipur during our door to door mobilization activity. She was already aware of the importance of learning computers and acquiring the skill, and was keen to enroll herself in our Data Entry Course after receiving counselling from the VTI team. During her classes, she was found to be adept at learning concepts easily and within no time her progress became evident. With a little support in developing her communication and soft skills, she was prepared for facing interviews confidently; and we are happy to share that she was selected as a sales girl cum cashier at IVT Store, Raipur with a monthly salary of INR 12000/-. Ranjeeta feels deeply grateful for this opportunity and is determined to make the most of it."
    },
 
    {  
         img:second,
        Name:"Zeeshan",
        text:"Zeeshan hails from Kashmir, and came to Chhattisgarh to live with his grandmother after the death of his parents. Before doing his course from Hermann Gmeiner Vocational Training Institute, Raipur , he was working in a private garage as cleaner and was earning INR 2,400/- per month. He came to know about the skilling programme being run by SOS, India from one of the alumni, who is currently working in a Bajaj showroom. Zeeshan shared- “Automotive Repair is something I always wanted to do. It fascinates me to overhaul, inspect and repair faults, as I find it challenging. Every technical fault has a unique aspect to it. The instructors at the center ensured theoretical and practical trainings, which helped strengthen my know-how significantly. Post training, I received a job with Maruti, where I am earning a stipend of INR 15,000/- per month. This is just the beginning of a long, yet interesting journey, where I can realize my potential and fulfil my dreams.”"
    },
 
    {   img:third,
        Name:"Tikeshwar Sahu",
        text:"Tikeshwar is a 22 year old boy who lives with his parents and 8 siblings. His father is a farmer and was the sole earning member of the family until Tikeshwar completed his skill training course from Hermann Gmeiner Vocational Training Institute, Raipur. Having completed his education upto class 12 th , he joined the VTI to gain training and certification as an Automotive Service Technician. He enrolled himself in the 6-month course to learn automotive repair training in 4-wheeler service. Thereafter, he was sent for a 15 day On-the-job training programme at Kaizen, Toyota, Raipur. After successfully completing his training and certification, he was selected as an Assistant Technician at Soni Motors, Tatibandh, where he was offered a monthly stipend of INR 10,000/-. He shared- “I’m happy that I have gained a skill that has provided me with a stable job. I’m able to support my family in a better way and help my younger siblings get basic education. This is a really big thing for me.”"
    },
]