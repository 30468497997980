import React from 'react'

const NizamudinCoursedata = (props) => {

    console.log(props)
    return (
        <>


            <section className='courseoffer'>


                <div className="container" style={{color: "rgba(0, 0, 0, 0.61)",
    fontWeight: "500"}}>
                    <h2>{props.data.head}</h2>
                    <p className='text-center pt-4 desc3'><b>{props.data.text}.</b></p>
                     <p className='desc2 py-2'>{props.data.subtext}</p>
                     <p className='desc2 py-2'>{props.data.subtext2}</p>
                     <p className='desc2 py-2'>{props.data.subtext3}</p>
                     





                </div>
            </section>


        </>
    )
}

export default NizamudinCoursedata
