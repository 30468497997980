import React from 'react'

const Workshopcard = (props) => {
    return (
        <>


            {props.data.map((e,ind) => {

                return <div class="col-lg-4" key={ind}>


                    <div class="card">
                        <img class="card-img-top" src={e.img} alt="Card image cap" />
                        <div class="card-body cc">
                            <h5 class="card-title text-center my-1">{e.title}</h5>


                        </div>
                    </div>



                </div>



            })}

        </>
    )
}

export default Workshopcard
