import React from 'react'
import { Row, Col } from "react-bootstrap";
import Computer from '../CourseOffered/Afterschoolcourses/Computer';

 import { computerData } from '../CourseOffered/Variable';


import "./faridabad.css"

const Varanasi = () => {
    return (
        <>
            <section className="coursecard">
                <div className="container-fluid">
                    <div style={{ marginBottom: "1.5rem" }}>
                        <h2
                            style={{
                                marginTop: "2rem",
                                textAlign: "center",
                                marginLeft: "-2rem",

                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "42px",
                                lineHeight: "51px",
                            }}
                        >
                            Course Offered
                        </h2>


                        <div className="coursecard">


                            <Row>

                                <Col sm="4">
                                    <Computer data={computerData}/>

                                </Col>







                            </Row>






                        </div>

                    </div>
                </div>

            </section>


        </>
    )
}

export default Varanasi
