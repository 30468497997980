import React from 'react'


import "./infra.css"
import Infracard from './Infracard'
 import infraCardData from './infracarddata'

const Infra = () => {
  return (
    <>
    


      <section className='infra'>

        <div className="container">

          <h2 className='text-center infrahead'>Infrastructure and Facilities</h2>


          <center>
            <div className="row mt-5">



              <Infracard data={infraCardData}/>

{/* 
              <div className="col-lg-4">

                <div class="card">
                  <img class="card-img-top" src="./images/office.jpg" alt="Card image cap" />
                  <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                  </div>
                </div>


              </div> */}
              {/* <div className="col-lg-4">

                <div class="card">
                  <img class="card-img-top" src="./images/office.jpg" alt="Card image cap" />
                  <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                  </div>
                </div>


              </div>
              <div className="col-lg-4">

                <div class="card ">
                  <img class="card-img-top" src="./images/office.jpg" alt="Card image cap" />
                  <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                  </div>
                </div>


              </div>
              <div className="col-lg-4">

                <div class="card" >
                  <img class="card-img-top" src="./images/office.jpg" alt="Card image cap" />
                  <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                  </div>
                </div>


              </div>
              <div className="col-lg-4 my-4">

                <div class="card">
                  <img class="card-img-top" src="./images/office.jpg" alt="Card image cap" />
                  <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                  </div>
                </div>


              </div> */}



            </div>
          </center>

        </div>
      </section>


    </>
  )
}

export default Infra
