import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./faridabad.css";
const url = "https://sos12.herokuapp.com/form-submit-palla";

const FaridabadContact = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [data, setData] = useState({
    Username: "",
    Email: "",
    Mobile: "",
    City: "",
    Course: "",
    Message: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const [isSubmit, setIsSubmit] = useState(false);

  const handle = (e) => {
    console.log(e);
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
    console.log(newdata);
  };
  function submit(e) {
    e.preventDefault();
    setLoading(true);

    setIsSubmit(true);
    Axios.post(url, {
      username: data.Username,
      email: data.Email,
      mobile: data.Mobile,
      city: data.City,
      course: data.Course,
      message: data.Message,
    })
      .then((res) => {
        console.log(res);
        if (res.data.msg === "Success") {
          setSuccess(true);
          setFormErrors({});
          setLoading(false);
          toast.success("Your response sent successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setData({
            Username: "",
            Email: "",
            Mobile: "",
            City: "",
            Course: "",
            Message: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("something went wrong!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }
  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(data);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};

    if (values.Username.length < 5) {
      errors.Username = `Username Length must be more than ${values.Username.length} Character `;
    } else if (values.Username.length > 10) {
      errors.Username = `Username Length must be less than ${values.Username.length} Character `;
    }

    if (values.Message.length < 5) {
      errors.Username = `Message Length must be more than ${values.Username.length} Character `;
    } else if (values.Message.length > 250) {
      errors.Username = `Message Length must be less than ${values.Username.length} Character `;
    }

    const emailregex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailregex.test(values.Email)) {
      errors.Email = "Please enter a valid email";
    }

    if (!values.Course) {
      errors.Course = "Course is Required";
    }

    return errors;
  };

  return (
    <>
      <div class="info">
        <h3 class="conhead">Drop us detail for quick response</h3>
        {success ? (
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        ) : (
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        )}
        <form onSubmit={(e) => submit(e)}>
          <div class="d-flex">
            <div class="mb-2">
              <div class="name contact-input">
                <img class="mx-4" src="./images/men.svg" alt="" />

                <input
                  onChange={(e) => handle(e)}
                  id="Username"
                  value={data.Username}
                  class="input-field"
                  type="text"
                  placeholder="Username"
                  name="Username"
                  required
                />
              </div>
              <p style={{ color: "red" }}>
                {formErrors.Username ? formErrors.Username : " "}
              </p>

              <div class="name contact-input">
                <img class="mx-4" src="./images/email.svg" alt="" />
                <input
                  onChange={(e) => handle(e)}
                  id="Email"
                  value={data.Email}
                  class="input-field"
                  type="text"
                  placeholder="Email"
                  name="Email"
                  required
                />
              </div>
              <p style={{ color: "red" }}>
                {formErrors.Email ? formErrors.Email : " "}
              </p>
              <div class="name contact-input">
                <img class="mx-4" src="./images/call.svg" alt="" />
                <input
                  onChange={(e) => handle(e)}
                  id="Mobile"
                  value={data.Mobile}
                  class="input-field"
                  type="text"
                  placeholder="Mobile"
                  name="Mobile"
                />
              </div>
            </div>
            <div class="secondinput">
              <div class="name contact-input">
                <img class="mx-4" src="./images/loc.svg" alt="" />

                <input
                  onChange={(e) => handle(e)}
                  id="City"
                  value={data.City}
                  class="input-field"
                  type="text"
                  placeholder="City"
                  name="City"
                  required
                />
              </div>
              <div class="name contact-input">
                <img class="mx-4" src="./images/coursw.svg" alt="" />
                <input
                  onChange={(e) => handle(e)}
                  id="Course"
                  value={data.Course}
                  class="input-field"
                  type="text"
                  placeholder="Course"
                  name="Course"
                  required
                />
              </div>
              <p style={{ color: "red" }}>
                {formErrors.Course ? formErrors.Course : " "}
              </p>
              <div class="name contact-input">
                <img class="mx-4" src="./images/msg.svg" alt="" />
                <input
                  onChange={(e) => handle(e)}
                  id="Message"
                  value={data.Message}
                  class="input-field"
                  type="text"
                  placeholder="Message"
                  name="Message"
                  required
                />
              </div>
              <p style={{ color: "red" }}>
                {formErrors.Message ? formErrors.Message : " "}
              </p>
            </div>
          </div>
          <div class="form-control">
            <button disabled={loading} class="for-btn btnn " id="btnn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default FaridabadContact;
