import React from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";

import "./faculty.css";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
const Faculty = () => {
  const [open, setOpen] = useState(false);
  const showData = (state) => {
    setOpen(!state);
  };
  const [open1, setOpen1] = useState(false);
  const showData1 = (state) => {
    setOpen1(!state);
  };

  console.log(open);
  return (
    <>
      <div className="contentmaincont">
        <div className="width">
          <Sidebar />
        </div>

        <Container>
          <section className="Table">
            <div class="mandatory_heading">
              <h2>Faculty</h2>
            </div>

            <div className="niz">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Nizamuddin
              </div>

              <div class="mandatory_table">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5">Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Mr. Bharat Rawat</div>
                  <div class="mandatory_col_last col-lg-5">Tally Trainer</div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">2</div>
                  <div class="mandatory_col col-lg-6">Ms. Chanda Kumari</div>
                  <div class="mandatory_col_last col-lg-5">
                    Web Designing Trainer
                  </div>
                </div>
              </div>
            </div>
            <div className="rai">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Raipur
              </div>

              <div class="mandatory_table">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5">Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Mr.Amrish Singh</div>
                  <div class="mandatory_col_last col-lg-5">
                    Project Incharge
                  </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">2</div>
                  <div class="mandatory_col col-lg-6">Mr. Yoginder Dhiman</div>
                  <div class="mandatory_col_last col-lg-5">
                    Automobile Instructure
                  </div>
                </div>
              </div>
            </div>
            <div className="bhakat">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Bhakatpara
              </div>

              <div class="mandatory_table">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5">Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Ms. Jury Das</div>
                  <div class="mandatory_col_last col-lg-5">
                    Handloom Weaving Trainer
                  </div>
                </div>
              </div>
            </div>
            <div className="faridabad">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Faridabad
              </div>

              <div class="mandatory_table">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5">Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Mr. Vikram Chawla</div>
                  <div class="mandatory_col_last col-lg-5">
                    Adminstrative Head
                  </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">2</div>
                  <div class="mandatory_col col-lg-6">Mr. Rajesh Singh</div>
                  <div class="mandatory_col_last col-lg-5">Tally Trainer</div>
                </div>

                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">2</div>
                  <div class="mandatory_col col-lg-6">Ms. Jyoti Bhadana</div>
                  <div class="mandatory_col_last col-lg-5">
                    Computer Trainer
                  </div>
                </div>
              </div>
            </div>

            <div className="palla">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Palla
              </div>

              <div class="mandatory_table">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5">Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Mr. Manish Kumar</div>
                  <div class="mandatory_col_last col-lg-5">
                    Data Entry Trainer
                  </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">2</div>
                  <div class="mandatory_col col-lg-6">Mr. Adarsh Tiwari</div>
                  <div class="mandatory_col_last col-lg-5">Tally Trainer</div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Anangpur
              </div>

              <div class="mandatory_table">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5">Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Ms. Lalita Rani</div>
                  <div class="mandatory_col_last col-lg-5">
                    Cutting and Tailoring Trainer
                  </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">2</div>
                  <div class="mandatory_col col-lg-6">Ms. Babli Bhadana</div>
                  <div class="mandatory_col_last col-lg-5">
                    Computer Trainer
                  </div>
                </div>
              </div>
            </div>
            <div className="varanasi">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Varanasi
              </div>

              <div class="mandatory_table">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5">Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Mr. D.N. Rajput</div>
                  <div class="mandatory_col_last col-lg-5">
                    Computer Trainer
                  </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">2</div>
                  <div class="mandatory_col col-lg-6">Mr. Anuj Kumar Singh</div>
                  <div class="mandatory_col_last col-lg-5">Mobilizer</div>
                </div>
              </div>
            </div>
            <div className="Bhopal">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Bhopal
              </div>

              <div class="mandatory_table  mb-5">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5">Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Mr Nitin Sahu</div>
                  <div class="mandatory_col_last col-lg-5">
                    Computer Trainer
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>
      </div>
    </>
  );
};

export default Faculty;
