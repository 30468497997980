import React from 'react'
import "./contactus.css"

const Contactcard = (props) => {
  return (
    <>

      {props.data.map((ele, ind) => {


        return <div className="col-lg-4">

          <div class="card" key={ind}>
            <img class="card-img-top"  style={{height:"30vh"}} src={ele.img} alt="Card image cap" />
            <div class="card-body">
              <h5 class="card-title">{ele.title}</h5>


              <p class="card-text">
                {ele.subtitle}

                <br />
                {ele.desc}
              </p>

              
              <p className='card-text'><span className='heading2'>Contact Person -:</span> {ele.person}</p>
             {ele.phone? <p className='card-text'><span className='heading2'>Phone-:</span> {ele.phone}</p>: ""}
              <p className='card-text'><span className='heading2'>Mobile-:</span> {ele.mobile}</p>
              <p className='card-text'><span className='heading2'>Email-:</span> {ele.email}</p>



            </div>
          </div>


        </div>





      })}

    </>
  )
}

export default Contactcard

{/* <div className="information">
<p className='heading2'>Contact Person-:</p>
<p>{ele.person}</p>
</div>
<div className="information">
<p className='heading2'>Phone -:</p>
<p>{ele.phone}</p>
</div>
<div className="information">
<p className='heading2'>Mobile-:</p>
<p>{ele.mobile}</p>
</div>
<div className="information">
<p className='heading2'>Email-:</p>
<p>{ele.email}</p>
</div> */}
