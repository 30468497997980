import React from 'react'

import "./workshop.css"
import Workshopcard from './Workshopcard'

import workshopData from './Workshopcarddata'
const Workshop = () => {
    return (
        <>
            <section class="workshop">



                <div class="container">


                    <h2 class=" text-center infrahead">Field Trips and Workshops</h2>


                    <center>
                        <div class="row mt-5">



                            <Workshopcard  data={workshopData}/>
                            {/* <div class="col-lg-4">


                                <div class="card">
                                    <img class="card-img-top" src="./images/office.jpg" alt="Card image cap" />
                                    <div class="card-body cc">
                                        <h5 class="card-title text-center my-1">Card title</h5>


                                    </div>
                                </div>



                            </div>
                            <div class="col-lg-4">

                                <div class="card">
                                    <img class="card-img-top" src="./images/office.jpg" alt="Card image cap" />
                                    <div class="card-body cc">
                                        <h5 class="card-title text-center my-1 ">Card title</h5>

                                    </div>
                                </div>



                            </div>
                            <div class="col-lg-4">

                                <div class="card">
                                    <img class="card-img-top" src="./images/office.jpg" alt="Card image cap" />
                                    <div class="card-body cc">
                                        <h5 class="card-title text-center my-1 ">Card title</h5>


                                    </div>
                                </div>



                            </div>

                            <div class="col-lg-4">

                                <div class="card">
                                    <img class="card-img-top" src="./images/office.jpg" alt="Card image cap" />
                                    <div class="card-body cc">
                                        <h5 class="card-title text-center my-1">Card title</h5>

                                    </div>
                                </div>



                            </div>
                            <div class="col-lg-4">

                                <div class="card">
                                    <img class="card-img-top" src="./images/office.jpg" alt="Card image cap" />
                                    <div class="card-body cc">
                                        <h5 class="card-title  text-center my-1 ">Card title</h5>


                                    </div>
                                </div>



                            </div>
                            <div class="col-lg-4">

                                <div class="card">
                                    <img class="card-img-top" src="./images/office.jpg" alt="Card image cap" />
                                    <div class="card-body cc">
                                        <h5 class="card-title text-center my-1">Card title</h5>


                                    </div>
                                </div>



                            </div> */}

                        </div>
                    </center>
                </div>



            </section>

        </>
    )
}

export default Workshop
