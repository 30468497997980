import React from 'react'
import { Link } from 'react-router-dom'
import "./footer.css"
const Footer = () => {
  return (
    <>

      {/* <footer>

        <div class="container-fluid content py-5">

          <div class="aboutus">

            <img src="./images/footerimg/1.png" className="footimg" alt="Footer img" />

          </div>

          <div class=" quicklinks">

            <h2>Quick Links</h2>

            <ul>
              <li><a href="#">About Us</a></li>
              <li><a href="#">VTI Nizamudin</a></li>
              <li><a href="#">VTI Raipur</a></li>
            </ul>
          </div>


          <div class="contact">


            <div className="flogo">

              <img src="./images/logo.svg" alt="" />
            </div>

            <ul>
              <h6>Contact us</h6>
              <li><a href="#">VTI Bhakatpara</a></li>
              <li><a href="#">VTI After School</a></li>
            </ul>
          </div>






        </div>

        
   
        
        <div class="copyright">

          <p>Copyright &copy; 2022 All rights reserved</p>
        </div>
      </footer> */}


      <footer className="text-center">


        <section className="container-fluid mt-4 mb-4">


          <div className="row">

            <div className="col-lg-5">

              <center>
                <div class="aboutus">

                  <img src="./images/footerimg/1.png" className='footimg' alt="Footer img" />

                </div>

              </center>


            </div>

            <div className="col-lg-3 ">



              <div class=" quicklinks">

                <h2>Quick Links</h2>

                <ul>
                  <li><Link to="/about">About Us</Link></li>
                  <li><Link to="/nizamudin">VTI Nizamuddin</Link></li>
                  <li><Link to="/raipur">VTI Raipur</Link></li>
                </ul>
              </div>

            </div>

            <div className="col-lg-3">

              <div class="contact">


                <div className="flogo">

                  <img src="./images/logo.svg" alt="" />
                </div>

                <ul>
                  <h6>Contact us</h6>
                  <li><a href="/bhakatpara">VTI Bhakatpara</a></li>
                  <li><a href="/school">After School VTIs</a></li>
                </ul>
              </div>
            </div>
          </div>


        </section>
        <div class="copyright">

          <p>Copyright &copy; 2022 All rights reserved</p>
        </div>
      </footer>

    </>
  )
}

export default Footer
