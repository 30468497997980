import dataentry from "../../images/Nizamimages/dataentry.jpg"
import tally from "../../images/Nizamimages/tally.jpg"
import web from "../../images/Nizamimages/web.webp"
import office from "../../images/Nizamimages/office.webp"
import python from "../../images/Nizamimages/python.jpg"
import reatil from "../../images/Nizamimages/retail.jpg"
import dekstop from "../../images/raipurimg/dekstop.jpg"
import vechile from "../../images/raipurimg/vechile1.jpg"
import automative from "../../images/raipurimg/automative.jpg"
import electrician from "../../images/raipurimg/electrician.jpg"

import handloom  from "../../images/bhakatparacourseimg/handloom.jfif"
import horiculture  from "../../images/bhakatparacourseimg/greenhouse.jpg"
import bee  from "../../images/bhakatparacourseimg/bee.jpg"
import aqua  from "../../images/bhakatparacourseimg/aqua.jfif"
import tailor from "../../images/bhakatparacourseimg/tailor.jpg"
import computer from "../../images/bhakatparacourseimg/computrer.jfif"
export const dataCourse = [

    {
        id: 1,
        img: dataentry,
        title: "Data Entry Operator",
        desc: "A basic level course that offers proficiency in MS Office and helps develop agamut of skills essential for a data entry and office executive positions.",
        subdesc:"",
        eligibility: "Min 10th Pass & 18 years of age",
        duration: "3 Months",
        Certification: "Niit Foundation"
    }




]


export const talyCourse = [

    {

        img: tally,
        title: "Tally Prime",
        desc: "A comprehensive accounting course that fully prepares one to manage  accounting of any firm, with in-depth knowledge and expertise of the Tally.",
        subdesc:"Prime Software.",
        eligibility: "Min 12th Pass & 18 years of age",
        duration: "6 Months",
        Certification: "Niit Foundation & Tally India Pvt.Ltd."
    }



]

export const webCourse = [

    {

        img: web,
        title: "Web Designing",
        desc: "One of the most sought after technical courses that offers a massive head-start for a lucrative career in IT-ITES industry as a web and graphic designer.",
        eligibility: "Min 12th Pass & 18 years of age",
        duration: "6 Months",
        Certification: "Niit Foundation"
    }



]

export const officeCourse = [
    {

        img: office,
        title: "Office Operations Executive",
        desc: "The perfect office executive course that prepares a candidate to build dexterity in managing routine office activities and inculcating admirable interpersonal skills for a successful career.",
        eligibility: "Min 12th Pass & 18 years of age",
        duration: "3 Months",
        Certification: "Management & Entrepreneurship Sector Skill Council"
    }



]

export const reatilCourse = [

    {

        img: reatil,
        title: "Retail & Sales cum Cashier",
        desc: "The course prepares youth for performing the role of a Retail Associate wherein they are thoroughly trained in Merchandise Management, developing customer relationship skills and",
        subdesc:"delivering quality service.",
        eligibility: "Min 10th Pass & 18 years of age",
        duration: "2 Months",
        Certification: "Retailers Association’s Skill Council of India."
    }



]

export const pythonCourse = [

    {

        img: python,
        title: "Python Fundamentals",
        desc: "A foundational course for learning Core Python, Ananconda IDE-Spyder, Jupyter, Google Colab, NumPy, Pandas, Matplotlib, sklearn etc,leading to careers in Data Analytics, Machine Learning ",
        subdesc:"and much more.",
        eligibility: "Min 10th Pass & 18 years of age",
        duration: "6 Months",
        Certification: "NASSCOM"
    }
]

export const associatDekstop = [
    {
        img: dekstop,
        title: "Associate- Desktop Publishing ",
        desc: "An associate in Desktop Publishing is trained to create reports and publications using tools like Adobe Acrobat, Adobe Flash, Macromedia and",
        subdesc:"Html and earns a certification in Desktop Publishing software tools.",
        eligibility: "Min 10th Pass & 18 years of age",
        duration: "6 Months",
        Certification: "NIIT Foundation &amp; Chhattisgarh State Govt."
    }



]
export const vechileData = [
    {

        img: vechile,
        title: "Light Motor Vehicle Driver Level 3",
        desc: "An automotive sector course that is aimed at preparing individuals to be fully trained in all the imperatives of being a skilled & informed Light.",
        subdesc:"Motor Vehicle Driver",
        eligibility: "Min 8th Pass & 18 years of age",
        duration: "3 Months",
        Certification: "Chhattisgarh State Govt."
    }



]

export const automativeData = [{
    img: automative,
    title: "Automotive Service Technician",
    desc: "An automotive service technician is trained to be able to perform repair work routine servicing and maintenance for two and three-wheelers",
    subdesc:" along wit essential awareness of various service requirements, policies and regulations. eligibility: Min 8th Pass & 18 years of age",
    eligibility: "Min 10th Pass & 18 years of age",
    duration: "3 Months",
    Certification: "Chhattisgarh State Govt."


},]


export const assistantData = [

    {
        img:electrician,
        title: "Assistant Electrician",
        desc:"The course trains for carrying out the installation of electrical wirings and cables to be able to provide support at construction sites and",
        subdesc:" permanent structures for. maintenance related to electrical works",
        eligibility: "Min 10th Pass & 18 years of age",
        duration: "3 Months",
        Certification: "Chhattisgarh State Govt."
    },



]

/////////// Bhakatpara card data //////////////////////////

export const handloomData = [

    {
        img:handloom,
        title: "Jacquard Handloom Weaving for Women",
        desc: "The course offers structured skill up-gradation training to women of Bhakatpara,in the art of Jacquard Handloom Weaving through optimization.",
        subdesc:"of technology for high-quality finished products",
        eligibility: "Min 10th Pass",
        duration: "6 Months",
        Certification: "Textile &Handloom sector Skill Council"
    }


    
]
export const aquaData = [

    {
        img:aqua,
        title: "Aqua culture worker",
        desc: "The course offers structured skill up-gradation training to women of Bhakatpara,in the art of Jacquard Handloom Weaving through optimization of technology for high-quality finished products.",
        eligibility: "Min 10th Pass",
        duration: "6 Months",
        Certification: "Textile &Handloom sector Skill Council"
    }


    
]
export const horicultureData = [

    {
        img:horiculture,
        title: "Horticulture",
        desc: "The course offers structured skill up-gradation training to women of Bhakatpara,in the art of Jacquard Handloom Weaving through optimization of technology for high-quality finished products.",
        eligibility: "Min 10th Pass",
        duration: "6 Months",
        Certification: "Textile &Handloom sector Skill Council"
    }


    
]
export const  beeData = [

    {
        img:bee,
        title: "Bee Keeper",
        desc: "The course offers structured skill up-gradation training to women of Bhakatpara,in the art of Jacquard Handloom Weaving through optimization of technology for high-quality finished products.",
        eligibility: "Min 10th Pass",
        duration: "6 Months",
        Certification: "Textile &Handloom sector Skill Council"
    }


    
]



//////////////////////////// After school cards ////////////////////////////////////

export const tailorData = [


    {
        img:tailor,
        title: "Cutting & Tailoring",
        desc: "A Self Help Group cum Vocational Training initiative that offers skill training resources to women of this marginalised community, enabling them to capitalize",
        subdesc:"heir talents for sustainable livelihood opportunities and entrepreneurship.",
        eligibility: "Min 10th Pass",
        duration: "6 Months",
        Certification: "Apparel Made-ups Home Furnishing Sector Skill Council"
    }



]


export const computerData=[

    {
        img:computer,
        title: "Course in Computer Concepts",
        desc: "An introductory course designed to impart working knowledge of MS Office and a skill-set that enables one to conduct office tasks like writing personal and business.",
        subdesc:"letters,performing research work on the internet, preparing and managing databases, etc",
        eligibility: "Min. 12 th Pass &amp; 18 years of age",
        duration: "3 Months",
        Certification: "National Institute of Electronics and Information Technology"
    }



]

export const operationData = [
    {

        img: office,
        title: "Office Operations Executive",
        desc: "The perfect office executive course that prepares a candidate to build dexterity in managing routine office activities and inculcating admirable interpersonal skills.",
        subdesc:"for a successful career",
        eligibility: "Min 12th Pass & 18 years of age",
        duration: "3 Months",
        Certification: "Management & Entrepreneurship Sector Skill Council"
    }



]