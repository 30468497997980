import React from 'react'
import "./nizamudin.css"
import { useState } from "react"
import useCollapse from "react-collapsed";
const NizaCard = ({ data }) => {

    // const [show, setShow] = useState(false)
    // const [id, setId] = useState(null)


    // const handleShow = (id) => {



    //     setId(id);

    //     setShow(!show)


    // }

    const [isExpanded, setExpanded] = useState(false);
    const { getCollapseProps, getToggleProps } = useCollapse();


    return (
        <>
            {data.map((ele, ind) => {







                return <div className="col-lg-4 col-sm-12">

                    <div div className="card " key={ind} >
                        <img className="card-img-top" src={ele.img} alt="Card image cap" />
                        <div className="card-body cc">
                            <h5 className="card-title text-center py-1">{ele.title}</h5>


                            <p className="card-text ccard">{ele.desc}





                                <p {...getCollapseProps()}>
                                    <p className='card-text'><span className='heading2'>Eligibility:</span> {ele.eligibility}</p>
                                    <p className='card-text'><span className='heading2'>Course Duration:</span> {ele.duration}</p>
                                    <p className='card-text'><span className='heading2'>Certification:</span> {ele.Certification}</p>
                                </p>





                            </p>











                        </div>
                        <div className="be btn">
                            <button  {...getToggleProps({
                                onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                            })} className="info btn">  {isExpanded ? "Read Less" : "Read More"}</button>
                        </div>
                    </div>
                </div>








            })}



        </>
    )
}

export default NizaCard
