import React from 'react'
import Navbar from "../navbar/Navbar"
import Home from "../Home/Home"
import Faculty from './Faculty'
import Footer from '../footer/Footer'

const Facultyhome = () => {
  return (
    <>
     <Navbar/> 
     <Home/>
     <Faculty/>
     <Footer/>
    </>
  )
}

export default Facultyhome
